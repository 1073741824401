@tailwind base;
@tailwind components;
@tailwind utilities;


@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.contact-info {
  color: white;
  font-size: 1.2em;
  font-weight: bold;
}


.navbar-nav {
  display: flex;
  gap: 1rem;
  justify-content: center;
  /* Center navigation items */
  align-items: center;
  /* Vertically align items */
  flex-wrap: nowrap;
  /* Prevent wrapping */
  white-space: nowrap;
  /* Prevent text wrapping */
}


@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column;
    /* Stack items vertically */
    align-items: center;
    /* Center items horizontally */
    justify-content: center;
    /* Center items vertically */
    gap: 1rem;
    /* Add consistent spacing between items */
  }

  .navbar-nav img {
    margin: 0 auto;
    /* Center the ISO logo */
    display: block;
    /* Ensure the logo appears on its line */
  }

  .navbar-nav .btn {
    align-self: center;
    /* Center the Contact Us button */
    margin: 0 auto;
    /* Ensure no unwanted margin offset */
  }
}

.dropdown:hover .dropdown-menu {
  display: block;
}